import {loadScript} from '../../helpers';
import {
  cleanAnalyticCallbacks,
  getAnalyticCallbacks,
  setAnalyticCallback,
  TRACKER_NAMES
} from '../analytic-callbacks';

class OnThePlugin {
  constructor() {
    this.isInitialized = false;
    this.VERSION = '0.2.0';
    this.init();
  }
    init = () => {
      if (window._io_config && window._io_config[this.VERSION]) {
        this.isInitialized = true;
        return;
      }

      loadScript(
        'ontheScript',
        window.Site.onTheScriptUrl,
        () => {
          const {targeting = {}} = window.Site;
          const config = {
            page_url: window.location.href,
            page_url_canonical: targeting.url_canonical,
            page_title: document.title,
            page_type: targeting.page_type,
            page_language: targeting.language ? targeting.language.toLowerCase() : ''
          };

          if (targeting.referer) {
            config.referer = targeting.referer;
          }

          if (targeting.page_type === 'article' || targeting.page_type === 'recipe') {
            config.article_id = Number(targeting.articleId);
            config.article_type = targeting.article_type;
            config.article_authors = targeting.authors;
            config.article_authors_ids = targeting.authorIds;
            config.article_categories = targeting.category_title;
            if (targeting.subcategory) {
              config.article_subcategories = targeting.subcategory;
            }
            config.article_publication_date = targeting.publication_date;
          }

          window._io_config = window._io_config || {};
          window._io_config.version = this.VERSION;
          window._io_config[this.VERSION] = window._io_config[this.VERSION] || [];
          window._io_config[this.VERSION].push(config);

          this.isInitialized = true;
          this.trackCallbacks();
        });
    }

    trackCallbacks = () => {
      const callbacks = getAnalyticCallbacks(TRACKER_NAMES.ON_THE);

      if (callbacks.length > 0) {
        callbacks.forEach(args => this.trackEvent(...args));
        cleanAnalyticCallbacks(TRACKER_NAMES.ON_THE);
      }
    }

    trackEvent = (...args) => {
      if (this.isInitialized) {
        window._io_config[this.VERSION].push(...args);
      } else {
        setAnalyticCallback(TRACKER_NAMES.ON_THE, args);
      }
    }
}

export {OnThePlugin};
