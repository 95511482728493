import {REQUEST_TIMEOUT_LIMIT} from './constants';

export const requestTimeoutLimit = () => {
  if (!window.AbortController) {
    return null;
  }
  const controller = new AbortController();

  setTimeout(() => controller.abort(), REQUEST_TIMEOUT_LIMIT);
  return controller.signal;
};
