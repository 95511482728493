import {JITSU_EVENT_TYPES} from '../../../../../constants/event-types/jitsu';
import {JitsuPlugin} from '../../../plugins/jitsu';
import {ANALYTIC_EVENTS} from '../../constants';

window.jitsuPlugin = window.jitsuPlugin || new JitsuPlugin();
const track = window.jitsuPlugin.trackEvent;

const mobileAuthEvents = {
  [ANALYTIC_EVENTS.LOGGED_OUT_STATE_OBSERVE]: () => track(JITSU_EVENT_TYPES.OBSERVE, {
    event_target: 'successful_logout_page',
    event_target_component: 'successful_logout_page',
    event_target_layout_section: 'main_content',
    feature: 'user_authentication'
  }),
  [ANALYTIC_EVENTS.LOGGED_OUT_STATE_BUTTON_CLICK]: () => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'back_to_app_button',
    event_target_component: 'successful_logout_page',
    event_target_layout_section: 'main_content',
    feature: 'user_authentication'
  }),
  [ANALYTIC_EVENTS.MOBILE_DELETE_SUCCESS_BUTTON_CLICK]: () => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'back_to_app_button',
    event_target_component: 'delete_profile_confirmation',
    event_target_layout_section: 'main_content',
    feature: 'user_authentication'
  }),
  [ANALYTIC_EVENTS.MOBILE_SIGN_UP_SUCCESS]: ({eventInitiator}) => track(JITSU_EVENT_TYPES.OBSERVE, {
    event_target: 'successful_auth_page',
    event_target_component: 'successful_auth_page',
    event_target_initiator: eventInitiator,
    event_target_layout_section: 'main_content',
    feature: 'user_authentication'
  }),
  [ANALYTIC_EVENTS.MOBILE_SIGN_UP_SUCCESS_BACK_TO_APP_CLICK]: () => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'back_to_app_button',
    event_target_component: 'successful_auth_page',
    event_target_layout_section: 'main_content',
    feature: 'user_authentication'
  })
};

export {mobileAuthEvents};
