import {JITSU_EVENT_TYPES} from '../../../../../constants/event-types/jitsu';
import {JitsuPlugin} from '../../../plugins/jitsu';
import {ANALYTIC_EVENTS} from '../../constants';

window.jitsuPlugin = window.jitsuPlugin || new JitsuPlugin();
const track = window.jitsuPlugin.trackEvent;

const requestErrorSubscriberEvents = {
  [ANALYTIC_EVENTS.INTERNAL_SERVER_ERROR]: data => track(JITSU_EVENT_TYPES.ERROR, data),
  [ANALYTIC_EVENTS.RATE_LIMIT_EXCEEDED_ERROR]: data => track(JITSU_EVENT_TYPES.ERROR, data)
};

export {requestErrorSubscriberEvents};
