import {JITSU_EVENT_TYPES} from '../../../../../constants/event-types/jitsu';
import {JitsuPlugin} from '../../../plugins/jitsu';
import {ANALYTIC_EVENTS} from '../../constants';

window.jitsuPlugin = window.jitsuPlugin || new JitsuPlugin();
const track = window.jitsuPlugin.trackEvent;

const FEATURE_NAME = 'user_authentication';

const restorePasswordSubscriberEvents = {
  [ANALYTIC_EVENTS.RESTORE_FORGOT_PASSWORD_CLICK]: () => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'password_recovery_button',
    event_target_component: 'password_verification',
    event_target_initiator: 'log_in',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.RESTORE_PHONE_STEP_NEXT_CLICK]: () => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'continue_button',
    event_target_component: 'phone_number_verification',
    event_target_initiator: 'password_recovery',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.RESTORE_ERROR_PROVIDER]: () => track(JITSU_EVENT_TYPES.ERROR, {
    event_target: 'continue_button',
    event_target_value: 'Login with this phone number only possible through Google',
    event_target_component: 'phone_number_verification',
    event_target_initiator: 'password_recovery',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.RESTORE_ERROR_NOT_FOUND]: () => track(JITSU_EVENT_TYPES.ERROR, {
    event_target: 'continue_button',
    event_target_value: 'Profile with this number not registered',
    event_target_component: 'phone_number_verification',
    event_target_initiator: 'password_recovery',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.RESTORE_ERROR_PHONE_FORMAT]: () => track(JITSU_EVENT_TYPES.ERROR, {
    event_target: 'continue_button',
    event_target_value: 'Enter a Kazakhstani operator phone number',
    event_target_component: 'phone_number_verification',
    event_target_initiator: 'password_recovery',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.RESTORE_CODE_STEP_NEXT_CLICK]: () => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'confirmation_button',
    event_target_component: 'sms_code_verification',
    event_target_initiator: 'password_recovery',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.RESTORE_PASSWORD_STEP_SUBMIT_CLICK]: () => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'save_button',
    event_target_component: 'new_password_verification',
    event_target_initiator: 'password_recovery',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.RESTORE_ERROR_PASSWORD_FORMAT]: () => track(JITSU_EVENT_TYPES.ERROR, {
    event_target: 'save_button',
    event_target_value: 'Password requirements not met',
    event_target_component: 'new_password_verification',
    event_target_initiator: 'password_recovery',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.RESTORE_ERROR_PASSWORD_INVALID_SYMBOLS]: () => track(JITSU_EVENT_TYPES.ERROR, {
    event_target: 'save_button',
    event_target_value: 'Password contains forbidden characters',
    event_target_component: 'new_password_verification',
    event_target_initiator: 'password_recovery',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.RESTORE_PASSWORD_SUCCESS]: () => track(JITSU_EVENT_TYPES.OBSERVE, {
    event_target: 'password_changed_notification',
    event_target_component: 'password_successfully_changed_page',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.RESTORE_PASSWORD_TO_LOGIN_CLICK]: () => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'log_in_button',
    event_target_component: 'password_successfully_changed_page',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  })
};

export {restorePasswordSubscriberEvents};
