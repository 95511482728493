// Page events
const PAGEVIEW = 'pageview';
const PAGE_VISIT = 'visit';
const PAGE_SCROLL = 'scroll';
const PAGE_RESIZE = 'resize';

// User events
const USER_LOGIN = 'user_login';
const USER_LOGOUT = 'user_logout';
const USER_REFRESH_TOKEN = 'user_refresh_token';
const USER_REGISTRATION = 'user_registration';
const USER_RECOVERY = 'user_recovery';
const USER_SUBSCRIBED = 'user_subscribed';
const USER_UNSUBSCRIBED = 'user_unsubscribed';
const USER_COMMENTS = 'user_comments';
const COMMENTS_REACTIONS = 'comments_reactions';
const SHARE = 'share_intent';
const TAP = 'tap';
const CHANGE = 'change';
const ERROR = 'error';

// UI events
const CLICK = 'click';
const IMPRESSION = 'impression';
const RESOLVE = 'resolve';
const OBSERVE = 'observe';

// Service events
const GROWTHBOOK_INIT = 'grb_init';

export const JITSU_EVENT_TYPES = Object.freeze({
  PAGEVIEW,
  PAGE_VISIT,
  PAGE_SCROLL,
  PAGE_RESIZE,
  USER_LOGIN,
  USER_LOGOUT,
  USER_REFRESH_TOKEN,
  USER_REGISTRATION,
  USER_RECOVERY,
  USER_SUBSCRIBED,
  USER_UNSUBSCRIBED,
  IMPRESSION,
  CLICK,
  USER_COMMENTS,
  COMMENTS_REACTIONS,
  SHARE,
  TAP,
  CHANGE,
  RESOLVE,
  OBSERVE,
  GROWTHBOOK_INIT,
  ERROR
});
