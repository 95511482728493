import {JITSU_EVENT_TYPES} from '../../../../../constants/event-types/jitsu';
import {JitsuPlugin} from '../../../plugins/jitsu';
import {ANALYTIC_EVENTS} from '../../constants';

window.jitsuPlugin = window.jitsuPlugin || new JitsuPlugin();
const track = window.jitsuPlugin.trackEvent;

const customEmbedRelatedContent = {
  [ANALYTIC_EVENTS.RELATED_CONTENT_CLICK]: () => {
    track(JITSU_EVENT_TYPES.TAP, {
      event_target: 'related_content_item',
      event_target_component: 'related_content_embed',
      event_target_layout_section: 'main_content',
      feature: 'related_content'
    });
  },
  [ANALYTIC_EVENTS.RELATED_CONTENT_OBSERVE]: () => {
    track(JITSU_EVENT_TYPES.OBSERVE, {
      event_target: 'related_content_embed',
      event_target_value: 50,
      event_target_component: 'related_content_embed',
      event_target_layout_section: 'main_content',
      feature: 'related_content'
    });
  }
};

export {customEmbedRelatedContent};
