import {JITSU_EVENT_TYPES} from '../../../../../constants/event-types/jitsu';
import {JitsuPlugin} from '../../../plugins/jitsu';
import {ANALYTIC_EVENTS} from '../../constants';

window.jitsuPlugin = window.jitsuPlugin || new JitsuPlugin();
const track = window.jitsuPlugin.trackEvent;

const FEATURE_NAME = 'user_authentication';

const loginSubscriberEvents = {
  [ANALYTIC_EVENTS.HEADER_BUTTON_CLICK]: () => {
    track(JITSU_EVENT_TYPES.TAP, {
      event_target: 'log_in_button',
      event_target_component: 'header',
      event_target_layout_section: 'app_bar',
      feature: FEATURE_NAME
    });
  },
  [ANALYTIC_EVENTS.LOGIN_WITH_GOOGLE_CLICK]: () => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'log_in_with_google_button',
    event_target_component: 'log_in_form',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.LOGIN_WITH_PHONE_CLICK]: () => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'log_in_with_phone_number_button',
    event_target_component: 'log_in_form',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.LOGIN_WITH_PHONE_NEXT_CLICK]: () => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'continue_button',
    event_target_component: 'phone_number_verification',
    event_target_initiator: 'log_in',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.LOGIN_WITH_PHONE_ERROR_PROVIDER]: () => track(JITSU_EVENT_TYPES.ERROR, {
    event_target: 'continue_button',
    event_target_value: 'Login with this phone number only possible through Google',
    event_target_component: 'phone_number_verification',
    event_target_initiator: 'log_in',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.LOGIN_WITH_PHONE_ERROR_NOT_FOUND]: () => track(JITSU_EVENT_TYPES.ERROR, {
    event_target: 'continue_button',
    event_target_value: 'Profile with this number not registered',
    event_target_component: 'phone_number_verification',
    event_target_initiator: 'log_in',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.LOGIN_WITH_PHONE_ERROR_FORMAT]: () => track(JITSU_EVENT_TYPES.ERROR, {
    event_target: 'continue_button',
    event_target_value: 'Enter a Kazakhstani operator phone number',
    event_target_component: 'phone_number_verification',
    event_target_initiator: 'log_in',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.LOGIN_WITH_PHONE_PASSWORD_NEXT_CLICK]: () => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'log_in_button',
    event_target_component: 'password_verification',
    event_target_initiator: 'log_in',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.LOGIN_WITH_PHONE_ERROR_PASSWORD_WRONG]: () => track(JITSU_EVENT_TYPES.ERROR, {
    event_target: 'log_in_button',
    event_target_value: 'Incorrect password',
    event_target_component: 'password_verification',
    event_target_initiator: 'log_in',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.LOGIN_WITH_PHONE_SUCCESS]: () => track(JITSU_EVENT_TYPES.RESOLVE, {
    event_target_value: 'You have successfully logged in to your profile!',
    event_target: 'phone_number_auth',
    event_target_initiator: 'log_in',
    event_target_layout_section: 'popover',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.LOGIN_WITH_GOOGLE_BUTTON_OBSERVE]: () => track(JITSU_EVENT_TYPES.RESOLVE, {
    event_target: 'log_in_with_google_button',
    event_target_component: 'log_in_form',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  })
};

export {loginSubscriberEvents};
