const loadScript = (id, src, handlerLoad, handlerError) => {
  if (!document.getElementById(id) || !id || !src) {
    const script = document.createElement('script');

    script.id = id;
    script.src = src;
    script.async = true;

    if (handlerError) {
      script.onerror = handlerError;
    }
    if (handlerLoad) {
      script.onload = handlerLoad;
    }

    document.body.appendChild(script);
  }
};

const checkControllerServiceWorker = () => {
  if (navigator.serviceWorker?.controller !== null) {
    const script = document.createElement('script');

    script.src = 'https://cdn.onesignal.com/sdks/OneSignalSDK.js';
    document.body.appendChild(script);
  }
};

const handleLazyLoadScript = () => {
  document.addEventListener('lazybeforeunveil', e => {
    const url = e.target.getAttribute('data-script');
    const name = e.target.getAttribute('data-name');

    if (url && name) {
      loadScript(name, url, () => {
        window.instgrm?.Embeds?.process();
      });
    }
  });
};

const handleLazyLoadBgImage = () => {
  document.addEventListener('lazybeforeunveil', e => {
    const imgUrl = e.target.getAttribute('data-bg-image');

    if (imgUrl) {
      e.target.style.backgroundImage = `url(${imgUrl})`;
    }
  });
};

const debounce = (fn, time) => {
  let timeout;

  return function() {
    const functionCall = () => fn.apply(this, arguments); /* eslint-disable-line */

    clearTimeout(timeout);
    timeout = setTimeout(functionCall, time);
  };
};

const getSimpleObserver = (callback, $container) => {
  const observer = new IntersectionObserver(changes => {
    changes.forEach(change => {
      if (!change.isIntersecting) {
        callback?.();
        observer.unobserve($container);
      }
    });
  });

  observer.observe($container);
};

const isInViewport = element => {
  const rect = element.getBoundingClientRect();
  const html = document.documentElement;

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || html.clientHeight) &&
    rect.right <= (window.innerWidth || html.clientWidth)
  );
};

export {
  loadScript,
  checkControllerServiceWorker,
  handleLazyLoadScript,
  handleLazyLoadBgImage,
  debounce,
  getSimpleObserver,
  isInViewport
};
