import {v4 as uuidv4} from 'uuid';

import {getCookie, setCookie} from './cookie';

const DEVICE_ID_COOKIE_KEY = 'device_id';
const HOURS_IN_10_YEARS = 87600;

const getDeviceId = () => {
  let deviceId = getCookie(DEVICE_ID_COOKIE_KEY);

  if (deviceId) {
    return deviceId;
  }

  deviceId = uuidv4();
  setCookie(DEVICE_ID_COOKIE_KEY, deviceId, HOURS_IN_10_YEARS);

  return deviceId;
};

export {getDeviceId};
