import {getArticleDetails} from './analytics/article-details';
import {publishEvent} from './analytics/pub-sub';
import {ANALYTIC_EVENTS} from './analytics/pub-sub/constants';

export const SHARE_EVENT_NAME = 'share_intent';

export const trackArticleShareEvent = (articleId, articleUrl, sharePlatform) => {
  publishEvent(ANALYTIC_EVENTS.SHARE, {
    ...getArticleDetails(articleId),
    share_platform: sharePlatform
  });
};

const handleArticleShareClick = e => {
  const shareButton = e.target.closest('.share-menu__button');

  if (shareButton) {
    const articleWrapper = e.target.closest('.js-article');
    const {platform, url} = shareButton.dataset;
    const {id: articleId} = articleWrapper.dataset;

    trackArticleShareEvent(articleId, url, platform);
  }
};

export const trackTrendShareEvent = (url, sharePlatform) => {
  publishEvent(ANALYTIC_EVENTS.SHARE, {
    share_platform: sharePlatform,
    url
  });
};

const handleTrendShareClick = event => {
  const shareButton = event.target.closest('.share-menu__button');

  if (shareButton) {
    const {platform, url} = shareButton.dataset;

    trackTrendShareEvent(url, platform);
  }
};

export const trackShares = () => {
  const articlesWrapper = document.querySelector('.js-content-type-page');
  const trendWrapper = document.querySelector('.js-trend');

  if (articlesWrapper) {
    articlesWrapper.addEventListener('click', handleArticleShareClick);
  } else if (trendWrapper) {
    trendWrapper.addEventListener('click', handleTrendShareClick);
  }
};

