const PROD_DOMAIN = 'nur.kz';

const FEATURE_NAMES = {
  ARTICLE_MENU_ABC: 'article_menu_abc_test',
  GOLOS_NARODA_VOTING_STATUS: 'golos_naroda:voting_status',
  ONE_TAP: 'auth:one_tap',
  RECOMMENDATION_URL_SUFFIX: 'recommendation:url_suffix',
  HOME_PAGE_ARTICLE_SNIPPET: 'comments:article_snippet',
  REACTIONS_ONBOARDING: 'onboarding:show_on_reactions',
  BOOKMARKS_ARTICLES: 'bookmarks:articles',
  WIDGET_PREGNANCY: 'widget:pregnancy',
  WIDGET_ROLE_UPGRADE: 'widget:user_role_upgrade'
};

const GROWTHBOOK_EVENTS = {
  LOAD: 'growthbook:loaded'
};

// If the network request takes longer than this (in milliseconds), continue
// Default: `0` (no timeout)
const LOAD_FEATURE_TIMEOUT = 3000;

const GOLOS_NARODA_STASUSES = {
  IN_DEVELOPMENT: 'development',
  ACTIVE: 'active',
  WAITING: 'waiting',
  FINISHED: 'finished',
  ACTIVE_WITHOUT_WIDGET: 'active_without_widget'
};

export {
  PROD_DOMAIN,
  GROWTHBOOK_EVENTS,
  FEATURE_NAMES,
  LOAD_FEATURE_TIMEOUT,
  GOLOS_NARODA_STASUSES
};
