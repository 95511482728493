import {loadScript} from '../../helpers';
import {cleanAnalyticCallbacks, getAnalyticCallbacks, setAnalyticCallback, TRACKER_NAMES} from '../analytic-callbacks';

class GTAGPlugin {
  constructor() {
    this.GTAG_ID = 'G-L3EYGX7DJS';
    this.isInitialized = false;

    this.init();
  }
  init = () => {
    if (window.gtag) {
      this.isInitialized = true;
      return;
    }
    loadScript(
      'gtagScript',
      `https://www.googletagmanager.com/gtag/js?id=${this.GTAG_ID}`,
      () => {
        window.dataLayer = window.dataLayer || [];
        window.gtag = function() {
          // eslint-disable-next-line prefer-rest-params
          window.dataLayer.push(arguments);
        };
        window.gtag('js', new Date());

        window.gtag('config', this.GTAG_ID, {
          transport_type: 'beacon',
          send_page_view: false
        });

        this.isInitialized = true;

        this.trackCallbacks();
      });
  }

  trackCallbacks = () => {
    const callbacks = getAnalyticCallbacks(TRACKER_NAMES.GTAG);

    if (callbacks.length > 0) {
      callbacks.forEach(args => this.trackEvent(...args));
      cleanAnalyticCallbacks(TRACKER_NAMES.GTAG);
    }
  }

  trackEvent = (...args) => {
    if (this.isInitialized) {
      window.gtag(...args);
    } else {
      setAnalyticCallback(TRACKER_NAMES.GTAG, [...args]);
    }
  }
}

export {GTAGPlugin};
