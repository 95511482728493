import {v4 as uuidv4} from 'uuid';

export const getBrowserTabId = () => {
  const browserTabId = sessionStorage.getItem('browserTabId');

  if (!browserTabId) {
    const newBrowserTabId = uuidv4();

    sessionStorage.setItem('browserTabId', newBrowserTabId);
    return newBrowserTabId;
  }
  return browserTabId;
};
