export const setArticleDetails = ({id, title, url: dataUrl}) => {
  const url = new URL(dataUrl);

  window.articleDetails[id] = {
    article_id: Number(id),
    doc_host: url.host,
    doc_path: url.pathname,
    page_title: title,
    url: dataUrl
  };
};
export const getArticleDetails = articleId => {
  const details = window.articleDetails[articleId];

  if (articleId && details) {
    return {
      ...details,
      doc_host: details.doc_host.replace(/^https?:\/\//, '')
    };
  }
  return {};
};
