import {JITSU_EVENT_TYPES} from '../../../../../constants/event-types/jitsu';
import {JitsuPlugin} from '../../../plugins/jitsu';
import {ANALYTIC_EVENTS} from '../../constants';

window.jitsuPlugin = window.jitsuPlugin || new JitsuPlugin();
const track = window.jitsuPlugin.trackEvent;

const FEATURE_NAME = 'user_authentication';

const signUpSubscriberEvents = {
  [ANALYTIC_EVENTS.SIGN_UP_WITH_GOOGLE_CLICK]: () => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'sign_up_with_google_button',
    event_target_component: 'sign_up_form',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.SIGN_UP_WITH_GOOGLE_SUCCESS]: ({
    isRegistration
  }) => track(JITSU_EVENT_TYPES.RESOLVE, {
    event_target: 'google_auth',
    event_target_value: 'You have successfully logged in to your profile!',
    event_target_initiator: isRegistration ? 'sign_up' : 'log_in',
    event_target_layout_section: 'popover',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.SIGN_UP_WITH_ONE_TAP_SUCCESS]: () => track(JITSU_EVENT_TYPES.RESOLVE, {
    event_target: 'google_auth',
    event_target_value: 'You have successfully logged in to your profile!',
    event_target_initiator: 'one_tap_auth',
    event_target_layout_section: 'popover',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.SIGN_UP_WITH_GOOGLE_BUTTON_OBSERVE]: () => track(JITSU_EVENT_TYPES.RESOLVE, {
    event_target: 'sign_up_with_google_button',
    event_target_component: 'sign_up_form',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  })
};

export {signUpSubscriberEvents};
