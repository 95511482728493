import {GrowthBook} from '@growthbook/growthbook';

import {getDeviceId} from '../services/device-id-helpers';
import {checkIsUserAuthenticated} from '../services/tokens';
import {getUserId} from '../services/user-id-helpers';

import {GROWTHBOOK_EVENTS, PROD_DOMAIN} from './constants';

export async function initGrowthBook() {
  const grb = new GrowthBook({
    enableDevMode: window.Site.domain !== PROD_DOMAIN,
    backgroundSync: false,
    ...window.Site.growthBookFeatures,
    attributes: {
      user_id: getUserId(),
      user_device_id: getDeviceId(),
      is_authenticated: checkIsUserAuthenticated(),
      url: window.location.href
    }
  });

  const growthBookEvent = new CustomEvent(GROWTHBOOK_EVENTS.LOAD, {
    detail: {growthBook: grb}
  });

  document.dispatchEvent(growthBookEvent);

  return grb;
}
