const USER_AGENT_FIELDS = {
  model: 'model',
  platform_version: 'platformVersion',
  full_version: 'uaFullVersion'
};

const getUserAgentData = async () => {
  if (!navigator.userAgentData || !navigator.userAgentData.getHighEntropyValues) {
    return null;
  }

  const filteredUserAgentData = {};

  const userAgentData = await navigator.userAgentData.getHighEntropyValues(Object.values(USER_AGENT_FIELDS)) || {};

  Object.entries(USER_AGENT_FIELDS).forEach(
    ([fieldName, fieldAPIName]) => {
      if (userAgentData[fieldAPIName]) {
        filteredUserAgentData[fieldName] = userAgentData[fieldAPIName];
      }
    }
  );

  return Object.keys(filteredUserAgentData).length > 0
    ? filteredUserAgentData
    : null;
};

export {getUserAgentData};
