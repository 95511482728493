export const MILLISECONDS_IN_ONE_HOUR = 3600 * 1000;
export const HOURS_IN_ONE_YEAR = 24 * 365;

// (key: String) => String | null
export function getCookie(key) {
  return decodeURIComponent(document.cookie.replace(new RegExp(`(?:(?:^|.*;)\\s*${key}\\s*\\=\\s*([^;]*).*$)|^.*$`), '$1')) || null; // eslint-disable-line
}

// (hours: Number) => String
function calculateExpireDate(hours) {
  const currentDate = new Date();

  currentDate.setTime(currentDate.getTime() + MILLISECONDS_IN_ONE_HOUR * hours);
  return currentDate.toUTCString();
}

function getExpirePart(hours) {
  return hours ? `expires=${calculateExpireDate(hours)};` : '';
}

// (key: String, value: String, hours: Number, domainName: String) => Void
export function setCookie(key, value, hours = 0, cookieDomain = window.Site.domain) {
  document.cookie = `${key}=${value}; ${getExpirePart(hours)}; domain=${cookieDomain}; path=/`;
}

// (key: String, domainName: String) => Void
export function deleteCookie(key, cookieDomain = window.Site.domain) {
  document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=${cookieDomain}; path=/`;
}

export function existsCookie(key) {
  return document.cookie.indexOf(key) !== -1;
}
