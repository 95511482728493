import {addJitsuProperty} from '../services/analytics/jitsu-helpers';

export async function getNotificationPermission() {
  try {
    const permission = await window.navigator.permissions.query({name: 'notifications'});

    permission.onchange = ({target = {}}) => {
      addJitsuProperty('permission_notification', target.state);
    };
    return permission.state === 'prompt' ? 'default' : permission.state;
  } catch (error) {
    if (window.Notification && window.Notification.permission) {
      return window.Notification.permission;
    }
    return 'Does not supported';
  }
}
