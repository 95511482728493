import {JITSU_EVENT_TYPES} from '../../../../../constants/event-types/jitsu';
import {JitsuPlugin} from '../../../plugins/jitsu';
import {ANALYTIC_EVENTS} from '../../constants';

window.jitsuPlugin = window.jitsuPlugin || new JitsuPlugin();
const track = window.jitsuPlugin.trackEvent;

const FEATURE_NAME = 'article_bookmarks';

const bookmarksSubscriberEvents = {
  [ANALYTIC_EVENTS.BOOKMARKS_ADD]: ({layoutSelection}) => {
    track(JITSU_EVENT_TYPES.CHANGE, {
      event_target: 'bookmark_toggler',
      event_target_value: 'add',
      event_target_component: 'article_action_menu',
      event_target_layout_section: layoutSelection,
      feature: FEATURE_NAME
    });
  },
  [ANALYTIC_EVENTS.BOOKMARKS_OPEN_LIST]: () => {
    track(JITSU_EVENT_TYPES.TAP, {
      event_target: 'bookmarks_summary',
      event_target_component: 'header',
      event_target_layout_section: 'app_bar',
      feature: FEATURE_NAME
    });
  },
  [ANALYTIC_EVENTS.BOOKMARKS_CLICK_ARTICLE]: () => {
    track(JITSU_EVENT_TYPES.TAP, {
      event_target: 'bookmarks_publication',
      event_target_component: 'bookmarks_publication',
      event_target_layout_section: 'right_drawer',
      feature: FEATURE_NAME
    });
  }
};

export {bookmarksSubscriberEvents};
