export const TRACKER_NAMES = {
  JITSU: 'jitsu',
  GTAG: 'gtag',
  GA: 'ga',
  ON_THE: 'onThe'
};

let analyticCallbacks = {};

export const setAnalyticCallback = (trackerName, callbackArguments) => {

  const trackerCallbacks = analyticCallbacks[trackerName] || [];

  analyticCallbacks = {
    ...analyticCallbacks,
    [trackerName]: [
      ...trackerCallbacks,
      callbackArguments
    ]
  };
};

export const getAnalyticCallbacks = trackerName => analyticCallbacks[trackerName] || [];

export const cleanAnalyticCallbacks = trackerName => Object.keys(analyticCallbacks)
  .reduce((result, key) => {
    if (key !== trackerName) {
      result[key] = analyticCallbacks[key];
    }
    return result;
  }, {});
