export const captureError = error => {
  try {
    import(/* webpackChunkName: "sentry" */ './services/sentry')
      .then(module => module.logError(error));
  } catch (e) {
    console.warn('fail load sentry', e);
  }
};

window.onerror = (message, url, line, column, error) => captureError(error);
window.onunhandledrejection = event => captureError(event.reason);
