import {JITSU_EVENT_TYPES} from '../../../../../constants/event-types/jitsu';
import {JitsuPlugin} from '../../../plugins/jitsu';
import {ANALYTIC_EVENTS} from '../../constants';

window.jitsuPlugin = window.jitsuPlugin || new JitsuPlugin();
const track = window.jitsuPlugin.trackEvent;

const adfoxSubscriberEvents = {
  [ANALYTIC_EVENTS.ADFOX_PLACEMENT_IMPRESSION]: ({containerId}) => track(JITSU_EVENT_TYPES.OBSERVE, {
    event_target: containerId,
    event_target_component: containerId,
    event_target_value: 100,
    feature: 'advertisement'
  }),
  [ANALYTIC_EVENTS.ADFOX_CREATIVE_IMPRESSION]: ({containerId}) => track(JITSU_EVENT_TYPES.OBSERVE, {
    event_target: 'banner',
    event_target_component: containerId,
    event_target_value: 100,
    feature: 'advertisement'
  }),
  [ANALYTIC_EVENTS.ADFOX_TAP_ON_BANNER]: ({containerId}) => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'banner',
    event_target_component: containerId,
    feature: 'advertisement'
  }),
  [ANALYTIC_EVENTS.ADFOX_STUB]: ({containerId}) => track(JITSU_EVENT_TYPES.RESOLVE, {
    event_target: containerId,
    event_target_component: containerId,
    event_target_value: 'No ad available for this placement',
    feature: 'advertisement'
  }),
  [ANALYTIC_EVENTS.ADFOX_ERROR]: ({containerId, message}) => track(JITSU_EVENT_TYPES.ERROR, {
    event_target: containerId,
    event_target_component: containerId,
    event_target_value: message,
    feature: 'advertisement'
  })
};

export {adfoxSubscriberEvents};
