import {JITSU_EVENT_TYPES} from '../../../../../constants/event-types/jitsu';
import {JitsuPlugin} from '../../../plugins/jitsu';
import {ANALYTIC_EVENTS} from '../../constants';

window.jitsuPlugin = window.jitsuPlugin || new JitsuPlugin();
const track = window.jitsuPlugin.trackEvent;

const FEATURE_NAME = 'user_profile';

const getWidgetName = ({
  hasPhoneNumber,
  hasNickname,
  isCollapsed = false
}) => {
  if (!hasNickname && !hasPhoneNumber) {
    return isCollapsed ? 'nickname_phone_number_collapsed_widget' : 'nickname_phone_number_absent_widget';
  }
  if (!hasPhoneNumber) {
    return isCollapsed ? 'phone_number_collapsed_widget' : 'phone_number_absent_widget';
  }
  return isCollapsed ? 'nickname_collapsed_widget' : 'nickname_absent_widget';
};

const commentAuthOverlaySubscriberEvents = {
  [ANALYTIC_EVENTS.WIDGET_COLLAPSE_TOGGLE]: ({
    isCollapsed,
    hasPhoneNumber,
    hasNickname
  }) => track(JITSU_EVENT_TYPES.CHANGE, {
    event_target: 'add_info_toggler',
    event_target_value: isCollapsed ? 'expand' : 'collapse',
    event_target_component: getWidgetName({
      hasPhoneNumber,
      hasNickname
    }),
    event_target_layout_section: 'right_drawer',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.WIDGET_NOT_NOW_CLICK]: ({
    hasPhoneNumber,
    hasNickname
  }) => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'not_now_button',
    event_target_component: getWidgetName({hasPhoneNumber, hasNickname}),
    event_target_layout_section: 'right_drawer',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.WIDGET_SET_PROFILE_INFO_CLICK]: ({
    hasPhoneNumber,
    hasNickname
  }) => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'set_button',
    event_target_component: getWidgetName({hasPhoneNumber, hasNickname}),
    event_target_layout_section: 'right_drawer',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.WIDGET_SET_PROFILE_INFO_NO_PHONE_CLICK]: () => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'set_button',
    event_target_component: 'phone_number_absent_widget',
    event_target_layout_section: 'right_drawer',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.WIDGET_SET_PROFILE_INFO_NO_NICKNAME_CLICK]: () => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'set_button',
    event_target_component: 'nickname_absent_widget',
    event_target_layout_section: 'right_drawer',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.WIDGET_COLLAPSED_LINK_CLICK]: ({
    hasPhoneNumber,
    hasNickname
  }) => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'set_button',
    event_target_component: getWidgetName({
      hasPhoneNumber,
      hasNickname,
      isCollapsed: true
    }),
    event_target_layout_section: 'right_drawer',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.WIDGET_EXPANDED_LINK_CLICK]: ({
    hasPhoneNumber,
    hasNickname
  }) => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'law_on_information_link',
    event_target_component: getWidgetName({
      hasPhoneNumber,
      hasNickname
    }),
    event_target_layout_section: 'right_drawer',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.WIDGET_EXPANDED_LINK_GROUP_B_CLICK]: () => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'law_on_information_link',
    event_target_component: 'phone_number_absent_widget',
    event_target_layout_section: 'right_drawer',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.WIDGET_ALL_PHONE_SUBMIT_CLICK]: () => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'continue_button',
    event_target_component: 'nickname_phone_number_absent_widget',
    event_target_initiator: 'phone_number_verification',
    event_target_layout_section: 'right_drawer',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.WIDGET_ALL_PHONE_CANCEL_CLICK]: () => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'cancel_button',
    event_target_component: 'nickname_phone_number_absent_widget',
    event_target_initiator: 'phone_number_verification',
    event_target_layout_section: 'right_drawer',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.WIDGET_ALL_SMS_CODE_SUBMIT_CLICK]: () => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'confirmation_button',
    event_target_component: 'nickname_phone_number_absent_widget',
    event_target_initiator: 'sms_code_verification',
    event_target_layout_section: 'right_drawer',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.WIDGET_ALL_PHONE_SUCCESS]: () => track(JITSU_EVENT_TYPES.RESOLVE, {
    event_target: 'add_phone_number',
    event_target_component: 'nickname_phone_number_absent_widget',
    event_target_value: 'Phone number was successfully saved',
    user_has_phone_linked: true,
    event_target_layout_section: 'popover',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.WIDGET_ALL_NICKNAME_SUBMIT_CLICK]: () => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'save_button',
    event_target_component: 'nickname_phone_number_absent_widget',
    event_target_initiator: 'add_nickname',
    event_target_layout_section: 'right_drawer',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.WIDGET_ALL_NICKNAME_CANCEL_CLICK]: () => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'cancel_button',
    event_target_component: 'nickname_phone_number_absent_widget',
    event_target_initiator: 'add_nickname',
    event_target_layout_section: 'right_drawer',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.WIDGET_ALL_NICKNAME_SUCCESS]: () => track(JITSU_EVENT_TYPES.RESOLVE, {
    event_target: 'add_nickname',
    event_target_component: 'nickname_phone_number_absent_widget',
    event_target_value: 'Nickname was successfully saved',
    user_has_nickname: true,
    event_target_layout_section: 'popover',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.WIDGET_PHONE_SUBMIT_CLICK]: () => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'continue_button',
    event_target_component: 'phone_number_absent_widget',
    event_target_initiator: 'phone_number_verification',
    event_target_layout_section: 'right_drawer',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.WIDGET_PHONE_CANCEL_CLICK]: () => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'cancel_button',
    event_target_component: 'phone_number_absent_widget',
    event_target_initiator: 'phone_number_verification',
    event_target_layout_section: 'right_drawer',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.WIDGET_PHONE_SMS_SUBMIT_CLICK]: () => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'confirmation_button',
    event_target_component: 'phone_number_absent_widget',
    event_target_initiator: 'sms_code_verification',
    event_target_layout_section: 'right_drawer',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.WIDGET_PHONE_SUCCESS]: () => track(JITSU_EVENT_TYPES.RESOLVE, {
    event_target: 'add_phone_number',
    event_target_component: 'phone_number_absent_widget',
    event_target_value: 'Phone number was successfully saved',
    user_has_phone_linked: true,
    event_target_layout_section: 'popover',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.WIDGET_NICKNAME_SUBMIT_CLICK]: () => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'save_button',
    event_target_component: 'nickname_absent_widget',
    event_target_initiator: 'add_nickname',
    event_target_layout_section: 'right_drawer',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.WIDGET_NICKNAME_CANCEL_CLICK]: () => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'cancel_button',
    event_target_component: 'nickname_absent_widget',
    event_target_initiator: 'add_nickname',
    event_target_layout_section: 'right_drawer',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.WIDGET_NICKNAME_SUCCESS]: () => track(JITSU_EVENT_TYPES.RESOLVE, {
    event_target: 'add_nickname',
    event_target_component: 'nickname_absent_widget',
    event_target_value: 'Nickname was successfully saved',
    user_has_nickname: true,
    event_target_layout_section: 'popover',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.WIDGET_ALL_PHONE_PROVIDER_ERROR]: () => track(JITSU_EVENT_TYPES.ERROR, {
    event_target: 'continue_button',
    // eslint-disable-next-line max-len
    event_target_value: 'The phone number you entered is already linked to another Google account. Log in to leave comments.',
    event_target_component: 'nickname_phone_number_absent_widget',
    event_target_initiator: 'phone_number_verification',
    event_target_layout_section: 'right_drawer',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.WIDGET_ALL_PHONE_LINKED_ERROR]: () => track(JITSU_EVENT_TYPES.ERROR, {
    event_target: 'continue_button',
    // eslint-disable-next-line max-len
    event_target_value: 'The number you entered is linked to another profile. If the phone number is yours, use it to log in.',
    event_target_component: 'nickname_phone_number_absent_widget',
    event_target_initiator: 'phone_number_verification',
    event_target_layout_section: 'right_drawer',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.WIDGET_PHONE_PROVIDER_ERROR]: () => track(JITSU_EVENT_TYPES.ERROR, {
    event_target: 'continue_button',
    // eslint-disable-next-line max-len
    event_target_value: 'The phone number you entered is already linked to another Google account. Log in to leave comments.',
    event_target_component: 'phone_number_absent_widget',
    event_target_initiator: 'phone_number_verification',
    event_target_layout_section: 'right_drawer',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.WIDGET_PHONE_LINKED_ERROR]: () => track(JITSU_EVENT_TYPES.ERROR, {
    event_target: 'continue_button',
    // eslint-disable-next-line max-len
    event_target_value: 'The number you entered is linked to another profile. If the phone number is yours, use it to log in',
    event_target_component: 'phone_number_absent_widget',
    event_target_initiator: 'phone_number_verification',
    event_target_layout_section: 'right_drawer',
    feature: FEATURE_NAME
  })
};

export {commentAuthOverlaySubscriberEvents};
