import {gtagSubscriber} from './subscribers/gtag';
import {jitsuSubscriber} from './subscribers/jitsu';
import {onTheSubscriber} from './subscribers/onthe';
import EventCenter from './event-center';
import EventDispatcher from './event-dispatcher';

const analyticsDispatcher = new EventDispatcher();

analyticsDispatcher.subscribe(jitsuSubscriber.getEvents());
analyticsDispatcher.subscribe(gtagSubscriber.getEvents());
analyticsDispatcher.subscribe(onTheSubscriber.getEvents());

// NOTE: can be used for example as modalCenter with modalDispatcher as param
const analyticsCenter = new EventCenter(analyticsDispatcher);

window.notificationDispatcher = window.notificationDispatcher || new EventDispatcher();
window.notificationCenter = window.notificationCenter || new EventCenter(window.notificationDispatcher);

const publishEvent = analyticsCenter.publish;

export {publishEvent};
