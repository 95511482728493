class EventDispatcher {
  constructor() {
    this.events = {};
  }

  subscribe = subscriber => {
    Object.entries(subscriber).forEach(([eventName, callback]) => {
      if (!this.events[eventName]) {
        this.events[eventName] = [];
      }
      this.events[eventName].push(callback);
    });
  }

  trigger = (eventName, eventParams = {}) => {
    if (this.events[eventName]) {
      this.events[eventName].forEach(callback => callback(eventParams));
    }
  }
}

export default EventDispatcher;
