import {captureError} from '../logger';

import {REQUEST_TIMEOUT_LIMIT} from './constants';
import {requestTimeoutLimit} from './request-timeout-limit';

const sendRequest = (url, apiMethod) => (
  data = {},
  onSuccess,
  onError,
  accessToken = null,
  responseWithBody = true,
  rawErrorHandler = null
  // eslint-disable-next-line max-params
) => {
  const authorizationHeader = accessToken ? {Authorization: `Bearer ${accessToken}`} : {};
  const headers = {
    'Content-Type': 'application/json',
    ...authorizationHeader
  };

  return fetch(url, {
    method: apiMethod,
    headers,
    body: JSON.stringify(data),
    signal: requestTimeoutLimit()
  })
    .then(response => {
      if (!response.ok) {
        const json = response?.json();

        if (rawErrorHandler) {
          rawErrorHandler({raw: response, data: json});
        }
        return json;
      }
      return responseWithBody ? response?.json() : {};
    })
    .then(response => (response?.error ? onError?.(response) : onSuccess?.(response)))
    .catch(error => {
      // catch timeout request error
      if (error.name === 'AbortError') {
        captureError(`Request canceled, timeout of ${REQUEST_TIMEOUT_LIMIT}ms exceeded`);
      }
      return onError?.(error);
    });
};

class HiveSDK {
  constructor(hiveUrl) {
    this.signUpUrl = `${hiveUrl}auth/signup`;
    this.signUpGoogleUrl = `${hiveUrl}auth/signup/google`;
    this.signUpCompleteUrl = `${hiveUrl}auth/signup/complete`;
    this.loginUrl = `${hiveUrl}auth/login`;
    this.loginValidateUrl = `${hiveUrl}auth/login/validate`;
    this.resetUrl = `${hiveUrl}auth/reset`;
    this.resetVerifyUrl = `${hiveUrl}auth/reset/confirm`;
    this.changePasswordUrl = `${hiveUrl}users/profile/password`;
    this.subscribeEmailUrl = `${hiveUrl}users/subscribe/email`;
    this.subscribePushUrl = `${hiveUrl}users/subscribe/push`;
    this.addPhoneUrl = `${hiveUrl}users/profile/complete`;
    this.refreshUrl = `${hiveUrl}auth/refresh`;
    this.phoneValidationUrl = `${hiveUrl}users/available/phone`;
    this.phoneReplaceUrl = `${hiveUrl}users/profile/phone`;
    this.phoneCompleteUrl = `${hiveUrl}users/profile/phone/complete`;
    this.userNameValidationUrl = `${hiveUrl}users/available/username`;
    this.deleteProfileUrl = `${hiveUrl}users/profile`;
    this.deleteProfileGoogleUrl = `${hiveUrl}users/profile/google`;
    this.linkEmailGoogleUrl = `${hiveUrl}users/profile/google/email`;
    this.confirmDeleteProfileUrl = `${hiveUrl}users/profile/deletion-complete`;
    this.subscribePregnantWidgetUrl = `${hiveUrl}subscription/pregnancy/email`;

    this.signUp = sendRequest(this.signUpUrl, 'post');

    this.signUpGoogle = sendRequest(this.signUpGoogleUrl, 'post');

    this.linkEmailGoogle = sendRequest(this.linkEmailGoogleUrl, 'post');

    this.signUpComplete = sendRequest(this.signUpCompleteUrl, 'post');

    this.login = sendRequest(this.loginUrl, 'post');

    this.loginValidate = sendRequest(this.loginValidateUrl, 'post');

    this.reset = sendRequest(this.resetVerifyUrl, 'post', true);

    this.changePassword = sendRequest(this.changePasswordUrl, 'put', true);

    this.refresh = sendRequest(this.refreshUrl, 'post');

    this.sendEmailSubscribe = sendRequest(this.subscribeEmailUrl, 'post');

    this.sendPushUserId = sendRequest(this.subscribePushUrl, 'post');

    this.sendPhoneReset = sendRequest(this.resetUrl, 'post');

    this.replacePhoneNumber = sendRequest(this.phoneReplaceUrl, 'put');

    this.phoneNumberComplete = sendRequest(this.phoneCompleteUrl, 'post');

    this.phoneValidation = sendRequest(this.phoneValidationUrl, 'post');

    this.userNameValidation = sendRequest(this.userNameValidationUrl, 'post');

    this.addPhone = sendRequest(this.addPhoneUrl, 'post');

    this.deleteProfile = sendRequest(this.deleteProfileUrl, 'delete');

    this.deleteProfileGoogle = sendRequest(this.deleteProfileGoogleUrl, 'delete');

    this.confirmDeleteProfile = sendRequest(this.confirmDeleteProfileUrl, 'post');

    this.subscribePregnantWidget = sendRequest(this.subscribePregnantWidgetUrl, 'post');
  }
}

const hiveSDK = new HiveSDK(window.Site?.authDomain);

export default hiveSDK;
