import {JITSU_EVENT_TYPES} from '../../../../../constants/event-types/jitsu';
import {JitsuPlugin} from '../../../plugins/jitsu';
import {ANALYTIC_EVENTS} from '../../constants';

window.jitsuPlugin = window.jitsuPlugin || new JitsuPlugin();
const track = window.jitsuPlugin.trackEvent;

const pregnantWidgetSubscriberEvents = {
  [ANALYTIC_EVENTS.PREGNANT_WIDGET_SHOW]: ({isMobile}) => track(JITSU_EVENT_TYPES.OBSERVE, {
    event_target: 'pregnancy_widget',
    event_target_value: isMobile ? 25 : 50,
    event_target_component: 'pregnancy_widget',
    event_target_layout_section: 'main_content_body',
    feature: 'pregnancy_newsletter'
  }),
  [ANALYTIC_EVENTS.PREGNANT_DEFAULT_INIT_SUBSCRIBE_CLICK]: () => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'subscribe_button',
    event_target_component: 'pregnancy_widget',
    event_target_layout_section: 'main_content_body',
    feature: 'pregnancy_newsletter'
  }),
  [ANALYTIC_EVENTS.PREGNANT_DEFAULT_CONTINUE_CLICK]: () => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'continue_button',
    event_target_component: 'pregnancy_widget',
    event_target_layout_section: 'main_content_body',
    feature: 'pregnancy_newsletter'
  }),
  [ANALYTIC_EVENTS.PREGNANT_DEFAULT_SUCCESS]: () => track(JITSU_EVENT_TYPES.RESOLVE, {
    event_target: 'pregnancy_widget',
    event_target_component: 'pregnancy_widget',
    event_target_value: 'You have successfully subscribed to our newsletter!',
    event_target_initiator: 'email_input',
    event_target_layout_section: 'main_content_body',
    feature: 'pregnancy_newsletter'
  }),
  [ANALYTIC_EVENTS.PREGNANT_DEFAULT_ERROR_REQUIRED]: () => track(JITSU_EVENT_TYPES.ERROR, {
    event_target: 'confirm_subscription_button',
    event_target_component: 'pregnancy_widget',
    event_target_value: 'Please fill in the email address field',
    event_target_layout_section: 'main_content_body',
    feature: 'pregnancy_newsletter'
  }),
  [ANALYTIC_EVENTS.PREGNANT_DEFAULT_ERROR_VALIDATION]: () => track(JITSU_EVENT_TYPES.ERROR, {
    event_target: 'confirm_subscription_button',
    event_target_component: 'pregnancy_widget',
    event_target_value: 'Invalid email address',
    event_target_layout_section: 'main_content_body',
    feature: 'pregnancy_newsletter'
  }),
  [ANALYTIC_EVENTS.PREGNANT_CONFIRM_CLICK]: () => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'confirm_subscription_button',
    event_target_component: 'pregnancy_widget',
    event_target_layout_section: 'main_content_body',
    feature: 'pregnancy_newsletter'
  }),
  [ANALYTIC_EVENTS.PREGNANT_GOOGLE_CONTINUE_CLICK]: () => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'continue_with_google_button',
    event_target_component: 'pregnancy_widget',
    event_target_layout_section: 'main_content_body',
    feature: 'pregnancy_newsletter'
  }),
  [ANALYTIC_EVENTS.PREGNANT_GOOGLE_LINK_ERROR]: () => track(JITSU_EVENT_TYPES.ERROR, {
    event_target: 'continue_with_google_button',
    event_target_component: 'pregnancy_widget',
    event_target_value:
      'Subscribing with this Google account is not possible because it is already linked to another profile',
    event_target_layout_section: 'main_content_body',
    feature: 'pregnancy_newsletter'
  }),
  [ANALYTIC_EVENTS.PREGNANT_GOOGLE_SUCCESS]: () => track(JITSU_EVENT_TYPES.RESOLVE, {
    event_target: 'pregnancy_widget',
    event_target_component: 'pregnancy_widget',
    event_target_value: 'You have successfully subscribed to our newsletter!',
    event_target_initiator: 'google_auth',
    event_target_layout_section: 'main_content_body',
    feature: 'pregnancy_newsletter'
  }),
  [ANALYTIC_EVENTS.PREGNANT_GOOGLE_LOGIN_SUCCESS]: () => track(JITSU_EVENT_TYPES.RESOLVE, {
    event_target: 'google_auth',
    event_target_value: 'You have successfully logged in to your profile!',
    event_target_initiator: 'pregnancy_widget',
    event_target_layout_section: 'main_content_body',
    feature: 'user_authentication'
  })
};

export {pregnantWidgetSubscriberEvents};
