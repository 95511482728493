import {OnThePlugin} from '../../plugins/onthe';
import {ANALYTIC_EVENTS} from '../constants';
import EventSubscriber from '../event-subscriber';

window.onThePlugin = window.onThePlugin || new OnThePlugin();
const track = window.onThePlugin.trackEvent;

const onTheSubscriber = new EventSubscriber({
  [ANALYTIC_EVENTS.PAGEVIEW_INFINITE]: eventData => track(eventData)
});

export {onTheSubscriber};
