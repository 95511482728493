import {JITSU_EVENT_TYPES} from '../../../../../constants/event-types/jitsu';
import {JitsuPlugin} from '../../../plugins/jitsu';
import {ANALYTIC_EVENTS} from '../../constants';

import {PHONE_NUMBER_ERRORS} from './constants';

window.jitsuPlugin = window.jitsuPlugin || new JitsuPlugin();
const track = window.jitsuPlugin.trackEvent;

const FEATURE_NAME = 'user_profile';

const profileSubscriberEvents = {
  [ANALYTIC_EVENTS.PROFILE_BUTTON_CLICK]: () => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'profile_button',
    event_target_component: 'header',
    event_target_layout_section: 'app_bar',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.PROFILE_LOGOUT_CLICK]: () => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'logout_button',
    event_target_component: 'logout_button',
    event_target_layout_section: 'left_sidebar',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.PROFILE_DELETE_BUTTON_CLICK]: () => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'delete_profile_button',
    event_target_component: 'delete_profile_button',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.PROFILE_REASON_NEXT_CLICK]: () => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'continue_button',
    event_target_component: 'delete_profile_reasons',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.PROFILE_REASON_CHANGE]: ({reason}) => track(JITSU_EVENT_TYPES.CHANGE, {
    event_target: 'delete_profile_reasons',
    event_target_component: 'delete_profile_reasons',
    event_target_value: reason,
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.PROFILE_REASON_ERROR]: () => track(JITSU_EVENT_TYPES.ERROR, {
    event_target: 'continue_button',
    event_target_component: 'delete_profile_reasons',
    event_target_value: 'Please select option',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.PROFILE_DELETE_REQUEST_SMS_CLICK]: () => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'send_sms_button',
    event_target_component: 'delete_profile_confirmation',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.PROFILE_DELETE_SUBMIT_SMS_CLICK]: () => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'send_request_button',
    event_target_component: 'phone_number_verification',
    event_target_initiator: 'delete_profile_confirmation',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.PROFILE_DELETE_SUBMIT_SUCCESS]: () => track(JITSU_EVENT_TYPES.RESOLVE, {
    event_target: 'delete_profile_confirmation',
    event_target_component: 'delete_profile_confirmation',
    event_target_value: 'Profile deletion request was successfully sent',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.PROFILE_DELETE_GOOGLE_REQUEST_CLICK]: () => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'delete_button',
    event_target_component: 'delete_profile_warning',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.PROFILE_DELETE_CONFIRM_MODAL_SHOW]: () => track(JITSU_EVENT_TYPES.OBSERVE, {
    event_target: 'delete_profile_confirmation',
    event_target_component: 'delete_profile_confirmation',
    event_target_layout_section: 'modal_window',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.PROFILE_DELETE_CONFIRM_MODAL_CONFIRM]: () => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'delete_button',
    event_target_component: 'delete_profile_confirmation',
    event_target_layout_section: 'modal_window',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.PROFILE_DELETE_SUCCESS_REDIRECT_CLICK]: () => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'go_to_homepage_button',
    event_target_component: 'delete_profile_success',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  }),
  // NICKNAME
  [ANALYTIC_EVENTS.PROFILE_NICKNAME_EDIT_CLICK]: ({action}) => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'edit_button',
    event_target_text_content: action,
    event_target_component: 'edit_nickname',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.PROFILE_NICKNAME_SIDEBAR_EDIT_CLICK]: ({isDesktop}) => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'edit_button',
    event_target_component: 'edit_nickname',
    event_target_layout_section: isDesktop ? 'left_sidebar' : 'top_aside',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.PROFILE_NICKNAME_EDIT_SUBMIT]: ({
    prevNickname,
    newNickname
  }) => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'save_button',
    event_target_value: newNickname,
    event_target_previous_value: prevNickname,
    event_target_component: 'edit_nickname',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.PROFILE_NICKNAME_ALREADY_EXISTS_ERROR]: () => track(JITSU_EVENT_TYPES.ERROR, {
    event_target: 'save_button',
    event_target_component: 'edit_nickname',
    event_target_value: 'Nickname is already taken',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.PROFILE_NICKNAME_EDIT_SUCCESS]: () => track(JITSU_EVENT_TYPES.RESOLVE, {
    event_target: 'edit_nickname',
    event_target_component: 'edit_nickname',
    event_target_value: 'Nickname was successfully saved',
    user_has_nickname: true,
    event_target_layout_section: 'popover',
    feature: FEATURE_NAME
  }),
  // PHONE NUMBER
  [ANALYTIC_EVENTS.PROFILE_PHONE_EDIT_CLICK]: ({action}) => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'edit_button',
    event_target_text_content: action,
    event_target_component: 'edit_phone_number',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.PROFILE_PHONE_EDIT_CONTINUE_CLICK]: () => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'continue_button',
    event_target_component: 'edit_phone_number',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.PROFILE_PHONE_CONFIRM_CONTINUE_CLICK]: () => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'confirmation_button',
    event_target_component: 'phone_number_verification',
    event_target_initiator: 'edit_phone_number',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.PROFILE_PHONE_EDIT_NUMBER_ERROR]: ({errorKey}) => track(JITSU_EVENT_TYPES.ERROR, {
    event_target: 'continue_button',
    event_target_value: PHONE_NUMBER_ERRORS[errorKey] || errorKey,
    event_target_component: 'edit_phone_number',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.PROFILE_PHONE_EDIT_SUCCESS]: () => track(JITSU_EVENT_TYPES.RESOLVE, {
    event_target: 'edit_phone_number',
    event_target_component: 'edit_phone_number',
    event_target_value: 'Phone number was successfully saved',
    user_has_phone_linked: true,
    event_target_layout_section: 'popover',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.PROFILE_PHONE_EDIT_ERROR]: ({errorKey}) => track(JITSU_EVENT_TYPES.ERROR, {
    event_target: 'confirmation_button',
    event_target_component: 'phone_number_verification',
    event_target_value: errorKey,
    event_target_initiator: 'edit_phone_number',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  }),
  //   BIRTH DATE
  [ANALYTIC_EVENTS.PROFILE_BIRTH_EDIT_CLICK]: ({action}) => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'edit_button',
    event_target_text_content: action,
    event_target_component: 'edit_date_of_birth',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.PROFILE_BIRTH_SAVE_CLICK]: () => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'save_button',
    event_target_component: 'edit_date_of_birth',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.PROFILE_BIRTH_EDIT_ERROR]: ({errorKey}) => track(JITSU_EVENT_TYPES.ERROR, {
    event_target: 'save_button',
    event_target_value: errorKey,
    event_target_component: 'edit_date_of_birth',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.PROFILE_BIRTH_EDIT_SUCCESS]: () => track(JITSU_EVENT_TYPES.RESOLVE, {
    event_target: 'edit_date_of_birth',
    event_target_value: 'Date of birth was successfully saved',
    event_target_component: 'edit_date_of_birth',
    event_target_layout_section: 'popover',
    feature: FEATURE_NAME
  }),
  //   GENDER
  [ANALYTIC_EVENTS.PROFILE_GENDER_EDIT_CLICK]: ({action}) => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'edit_button',
    event_target_text_content: action,
    event_target_component: 'edit_gender',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.PROFILE_GENDER_SAVE_CLICK]: () => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'save_button',
    event_target_component: 'edit_gender',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.PROFILE_GENDER_EDIT_ERROR]: ({errorKey}) => track(JITSU_EVENT_TYPES.ERROR, {
    event_target: 'save_button',
    event_target_value: errorKey,
    event_target_component: 'edit_gender',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.PROFILE_GENDER_EDIT_SUCCESS]: () => track(JITSU_EVENT_TYPES.RESOLVE, {
    event_target: 'edit_gender',
    event_target_value: 'Gender was successfully saved',
    event_target_component: 'edit_gender',
    event_target_layout_section: 'popover',
    feature: FEATURE_NAME
  }),
  //   PASSWORD
  [ANALYTIC_EVENTS.PROFILE_PASSWORD_EDIT_CLICK]: () => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'edit_button',
    event_target_component: 'edit_password',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.PROFILE_PASSWORD_SMS_CONTINUE]: () => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'confirmation_button',
    event_target_component: 'phone_number_verification',
    event_target_initiator: 'edit_password',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.PROFILE_PASSWORD_SAVE]: () => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'save_button',
    event_target_component: 'edit_password',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.PROFILE_PASSWORD_SMS_ERROR]: ({errorKey}) => track(JITSU_EVENT_TYPES.ERROR, {
    event_target: 'confirmation_button',
    event_target_value: errorKey,
    event_target_component: 'phone_number_verification',
    event_target_initiator: 'edit_password',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.PROFILE_PASSWORD_ERROR]: ({errorKey}) => track(JITSU_EVENT_TYPES.ERROR, {
    event_target: 'save_button',
    event_target_value: errorKey,
    event_target_component: 'edit_password',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.PROFILE_PASSWORD_SUCCESS]: () => track(JITSU_EVENT_TYPES.RESOLVE, {
    event_target: 'edit_password',
    event_target_value: 'Password was successfully changed',
    event_target_component: 'edit_password',
    event_target_layout_section: 'popover',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.PROFILE_EDIT_COMMON_ERROR]: ({errorKey}) => track(JITSU_EVENT_TYPES.ERROR, {
    event_target_value: errorKey,
    event_target_layout_section: 'popover',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.PROFILE_GOOGLE_LINK_CLICK]: () => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'link_with_Google_button',
    event_target_component: 'link_with_Google_button',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.PROFILE_GOOGLE_LINK_SUCCESS]: () => track(JITSU_EVENT_TYPES.RESOLVE, {
    event_target: 'google_auth',
    event_target_value: 'Your Google profile has been successfully added!',
    event_target_initiator: 'link_acc_with_Google',
    event_target_layout_section: 'popover',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.PROFILE_GOOGLE_LINK_ERROR_REGISTERED]: () => track(JITSU_EVENT_TYPES.ERROR, {
    event_target: 'link_with_Google_button',
    // eslint-disable-next-line max-len
    event_target_value: 'Unable to link current Google account as it is already exists. If it is yours, follow the instructions',
    event_target_component: 'link_with_Google_button',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  }),
  [ANALYTIC_EVENTS.PROFILE_GOOGLE_LINK_ERROR_EXIST]: () => track(JITSU_EVENT_TYPES.ERROR, {
    event_target: 'link_with_Google_button',
    event_target_value: 'Unable to link your Google account because it is already associated with another profile',
    event_target_component: 'link_with_Google_button',
    event_target_layout_section: 'main_content',
    feature: FEATURE_NAME
  })
};

export {profileSubscriberEvents};
