const userContract = Object.seal({
  isAuthenticated: false,
  id: '',
  username: ''
});

function validateUser (user) {
  if (typeof user.id !== 'string') {
    throw Error(`Id of user should be string, received ${typeof user.id}`);
  }
  return true;
}

function createUser(user) {
  return validateUser(user) ? {...user} : {};
}

export {createUser, userContract};
