import {JITSU_EVENT_TYPES} from '../../../constants/event-types/jitsu';
import {getBrowserTabId} from '../../../helpers/getBrowserTabId';
import {getNotificationPermission} from '../../../helpers/getNotificationPermission';
import {isStorageSupported} from '../../../helpers/isStorageSupported';
import {getUserAgentData} from '../../../helpers/user-agent-client-hints';
import {getDeviceId} from '../../device-id-helpers';
import {checkIsUserAuthenticated, getAccessToken, getTokenPayload} from '../../tokens';
import {getUserId} from '../../user-id-helpers';
import {
  cleanAnalyticCallbacks,
  getAnalyticCallbacks,
  setAnalyticCallback,
  TRACKER_NAMES
} from '../analytic-callbacks';
import {createPageviewPayload} from '../jitsu-helpers';

class JitsuPlugin {
  constructor() {
    this.isInitialized = false;
    this.init()
      .then(isInitialized => {
        this.isInitialized = isInitialized;

        if (isInitialized) {
          this.trackCallbacks();
        }
      });
  }
  init = async () => {
    if (__JITSU_ENABLED__ && __JITSU_ENABLED__ !== 'false') {
      if (window.jitsu && window.jitsu.initialized) {
        return true;
      }

      const {jitsuParams = {}, targeting = {}} = window.Site;

      const {jitsuClient} = await import('@jitsu/sdk-js');

      const jitsu = jitsuClient({
        key: jitsuParams.key,
        tracking_host: jitsuParams.host,
        log_level: 'ERROR'
      });

      const playerId = isStorageSupported('localStorage')
        ? localStorage.getItem('oneSignalPlayerId')
        : null;

      if (playerId) {
        jitsu.set({player_id: playerId});
      }

      const userAgentData = await getUserAgentData();

      if (userAgentData) {
        jitsu.set({
          sec_ch_ua: userAgentData
        });
      }

      const notificationPermission = await getNotificationPermission();

      const isAuthenticated = checkIsUserAuthenticated();
      const {
        role,
        provider,
        id: accountId,
        username = ''
      } = getTokenPayload(getAccessToken());

      if (isAuthenticated) {
        jitsu.set({
          registration_source: provider || 'No data provided',
          user_has_google_linked: provider === 'google',
          user_has_nickname: Boolean(username),
          user_has_phone_linked: role === 'extended'
        });
        await jitsu.id({
          account_id: accountId
        }, true);
      } else {
        jitsu.unset('registration_source');
        jitsu.unset('user_has_google_linked');
        jitsu.unset('user_has_phone_linked');
        jitsu.unset('user_has_nickname');
      }

      jitsu.set({
        env: jitsuParams.env,
        source_type: targeting.platform,
        browser_tab_id: getBrowserTabId(),
        is_authenticated: isAuthenticated,
        permission_notification: notificationPermission
      });

      await jitsu.id({
        id: getUserId(),
        device_id: getDeviceId()
      }, true);

      const queryParams = new URLSearchParams(window.location.search);
      const userTransitionDeviceId = queryParams.get('userTransitionDeviceId');

      if (userTransitionDeviceId) {
        await jitsu.id({
          transition_device_id: userTransitionDeviceId
        }, true);
      }

      window.jitsu = jitsu;

      return true;
    }
    return false;
  }

  trackCallbacks = () => {
    const jitsuCallbacks = getAnalyticCallbacks(TRACKER_NAMES.JITSU);

    if (jitsuCallbacks.length > 0) {
      jitsuCallbacks.forEach(args => this.trackEvent(...args));
      cleanAnalyticCallbacks(TRACKER_NAMES.JITSU);
    }
  }

  trackPageview = () => {
    const targeting = window.Site.targeting || {};

    this.trackEvent(JITSU_EVENT_TYPES.PAGEVIEW, createPageviewPayload({
      ...targeting,
      contentType: targeting?.attr_content_type
    }));
  }

  trackEvent = (eventType, eventProps) => {
    if (this.isInitialized) {
      return window.jitsu.track(eventType, eventProps);
    }
    setAnalyticCallback(TRACKER_NAMES.JITSU, [eventType, eventProps]);
  }
}

export {JitsuPlugin};
