import {getAccessToken, isTokenValid} from '../tokens';

export function addJitsuUserProperty (property, value) {
  const jitsu = window.jitsu;

  if (jitsu && jitsu.initialized) {
    jitsu.id({
      [property]: value
    }, true);
  }
}

export function addJitsuProperty (property, value) {
  const jitsu = window.jitsu;

  if (jitsu && jitsu.initialized) {
    jitsu.set({
      [property]: value
    });
  }
}

export function removeJitsuProperty (property) {
  const jitsu = window.jitsu;

  if (jitsu && jitsu.initialized) {
    jitsu.unset(property);
  }
}

export function createPageviewPayload ({
  articleId,
  page,
  category = [],
  subcategory = [],
  authorIds = [],
  contentType = '',
  referer
}) {
  const accessToken = getAccessToken();

  const commonPayload = {
    is_logged: isTokenValid(accessToken)
  };

  if (referer) {
    commonPayload.referer = referer;
  }

  const extendedPayload = {
    ...commonPayload,
    page_category: category.join()
  };

  if (subcategory.length) {
    extendedPayload.page_subcategory = subcategory.join();
  }

  switch (page) {
    case 'Article':
      return {
        ...extendedPayload,
        article_id: articleId ? Number(articleId) : '',
        article_authors_ids: authorIds.join(),
        article_attr_content_type: contentType
      };
    case 'Rubric':
      return extendedPayload;
    default:
      return commonPayload;
  }
}

export const refreshUserProperties = payload => {
  addJitsuProperty('is_authenticated', true);
  addJitsuProperty('registration_source', payload.provider || 'No data provided');
  addJitsuProperty('user_has_google_linked', payload.provider === 'google');
  addJitsuProperty('user_has_phone_linked', payload.role === 'extended');
  addJitsuProperty('user_has_nickname', Boolean(payload.username));
  addJitsuUserProperty('account_id', payload.id);
};

export const cleanUserProperties = () => {
  addJitsuProperty('is_authenticated', false);
  removeJitsuProperty('registration_source');
  removeJitsuProperty('user_has_google_linked');
  removeJitsuProperty('user_has_phone_linked');
  removeJitsuProperty('user_has_nickname');
};
