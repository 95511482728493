import {v4 as uuidv4} from 'uuid';

import {getCookie, setCookie} from './cookie';
import {
  getAccessToken,
  getTokenPayload,
  isTokenValid,
  setAuthorizationData
} from './tokens';

const USER_ID_STORAGE_KEY = 'nur_user_id';
const EXPIRES_HOURS = 10000;

const setStorageUserId = hash => {
  setCookie(USER_ID_STORAGE_KEY, hash, EXPIRES_HOURS);
};

export const getStorageUserId = () => (
  getCookie(USER_ID_STORAGE_KEY) || null
);

export const setAuthorizationDataAndUserId = (withUserIdOverwrite, data) => {
  if (withUserIdOverwrite && data.id) {
    setStorageUserId(data.id);
  }

  setAuthorizationData(data);
};

const getUserIdUnloggedUser = () => {
  const userId = getStorageUserId();

  if (!userId) {
    const newUserId = uuidv4();

    setStorageUserId(newUserId);

    return newUserId;
  }

  return userId;
};

const getUserIdLoggedUser = accessToken => {
  let {_id: userId} = getTokenPayload(accessToken);

  if (!userId) {
    userId = getStorageUserId() || uuidv4();
  }

  setStorageUserId(userId);

  return userId;
};

export const getUserId = () => {
  const accessToken = getAccessToken();

  if (isTokenValid(accessToken)) {
    return getUserIdLoggedUser(accessToken);
  }

  return getUserIdUnloggedUser();
};
